@tailwind base;
@tailwind components;
@tailwind utilities;

.noticias h1, .noticias h2, .noticias h3, .noticias h4, .noticias h5 {
  font-weight: bold;
}
.noticias ul {
  margin-left: 1rem;
  padding-bottom: 1rem;
}
.noticias ul li {
  list-style: inside;
}
